<template>
  <div class="goods2">
    <van-nav-bar
      :fixed="true"
      left-arrow
      placeholder
      @click-left="onClickLeft"
      :title="$t('Home.RechargeSu.Recharge')"
    />
    <div class="Recharge">
      <div class="txt">{{ $t('Home.RechargeSu.RechargeAm') }}</div>
      <div class="Recharge-cu">
        <span>{{ $t('Home.RP') }}</span>
        <input
          v-model="money"
          type="text"
          :placeholder="$t('Home.RechargeSu.Enter')"
        />
      </div>
      <!-- 输入姓名 -->
      <!-- <div class="Recharge-cu">
        <span>{{ $t('Home.RechargeSu.name') }}</span>
        <input
          v-model="name"
          type="text"
          :placeholder="$t('Home.RechargeSu.nameP')"
        />
      </div> -->
      <div class="pull-down">
        <!-- <span>{{ $t('My.PersonalInformation.BankAccounts.BankName') }}</span> -->
        <van-dropdown-menu>
          <van-dropdown-item v-model="godds" :options="optionData" />
        </van-dropdown-menu>
      </div>
      <div class="fast">{{ $t('Home.RechargeSu.Quick') }}</div>
      <div class="money">
        <div
          v-for="item in RechargeData"
          :key="item.id"
          @click="Recharge(item)"
        >
          {{ item.number }}
        </div>
      </div>
    </div>
    <!-- 充值按钮 -->
    <button @click="RechargeBtn" class="btn-div1">
      {{ $t('Home.RechargeSu.Next') }}
    </button>
    <!-- 充值事项 -->
    <div class="matter-box">
      <div class="Catatan">{{ $t('Home.RechargeSu.Catatan') }}</div>
      <div v-for="item in matterData" :key="item.id">{{ item.txt }}</div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { NavBar, Dialog, Toast, DropdownMenu, DropdownItem } from 'vant'
import { REQUEST_API } from '@/http/api'
import { countTime, getMyTime, deciTurnMoney } from '@/utils/tools'
Vue.use(NavBar)
export default {
  name: 'Recharge',
  components: {
    [NavBar.name]: NavBar,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
  data() {
    return {
      // 充值跳转地址
      // 用户姓名
      name: '',
      // 用户信息
      userData: '',
      uid: '',
      // 充值金额
      RechargeData: [
        { number: '100,000' },
        { number: '200,000' },
        { number: '500,000' },
        { number: '1,000,000' },
        { number: '2,000,000' },
        { number: '5,000,000' },
        { number: '10,000,000' },
        { number: '20,000,000' },
      ],
      // 充值事项
      matterData: [
        {
          txt:
            '1. Jumlah pembayaran akhir harus sama dengan jumlah yang Anda pilih sekarang, jika tidak maka tidak dapat dikreditkan ke akun secara real time. Jika Anda membayar dengan jumlah yang salah, bank akan membutuhkan waktu 3 ~ 5 hari untuk memeriksanya. Harap kirimkan voucher pembayaran ke layanan pelanggan. Ingatkan lagi: Agar pengisian ulang ke akun lancar dan tepat waktu, harap perhatikan jumlah yang sama!',
        },
        {
          txt:
            '2. Harap selesaikan pembayaran dalam waktu dua jam, kode VA berlaku selama dua jam, dan akan menjadi tidak valid setelah waktunya habis!',
        },
      ],
      money: '',
      optionData: [
        {
          text: 'Bank Central Asia',
          value: 'BCA.FT',
          code: 'BCA.VA',
        },
        {
          text: 'Bank Negara Indonesia',
          value: 'BNI.FT',
          code: 'BNI.VA',
        },
        {
          text: 'Bank Mandiri',
          value: 'MDR.FT',
          code: 'MDR.VA',
        },
        // {
        //   text: 'Bank Rakyat Indonesia',
        //   value: 'BRI.FT',
        //   code: 'BRI.VA',
        // },

        {
          text: 'Bank Permata',
          value: 'PTB.FT',
          code: 'PTB.VA',
        },
        {
          text: 'Maybank Indonesia',
          value: 'BMI.FT',
          code: 'BMI.VA',
        },

        // {
        //   text: 'Bank Artha Graha',
        //   value: 'BAG.FT',
        //   code: 'BAG.VA',
        // },
        // {
        //   text: 'Bank Bukopin',
        //   value: 'BBKP.FT',
        //   code: 'BBKP.VA',
        // },
        // {
        //   text: 'Bank Commonwealth',
        //   value: 'BCW.FT',
        //   code: 'BCW.VA',
        // },
        // {
        //   text: 'Bank Danamon',
        //   value: 'BDM.FT',
        //   code: 'BDM.VA',
        // },
        // {
        //   text: 'Bank Mega',
        //   value: 'BMG.FT',
        //   code: 'BMG.VA',
        // },
        // {
        //   text: 'Bank Maspion',
        //   value: 'BMP.FT',
        //   code: 'BMP.VA',
        // },
        // {
        //   text: 'BMTK Bank Mestika',
        //   value: 'BMTK.FT',
        //   code: 'BMTK.VA',
        // },
        // {
        //   text: 'Bank Panin',
        //   value: 'BPN.FT',
        //   code: 'BPN.VA',
        // },
        // {
        //   text: 'Bank Sumut',
        //   value: 'BSMT.FT',
        //   code: 'BSMT.VA',
        // },
        // {
        //   text: 'Bank Sinar Mas',
        //   value: 'BSM.FT',
        //   code: 'BSM.VA',
        // },
        // {
        //   text: 'Bank Btpn',
        //   value: 'BTPN.FT',
        //   code: 'BTPN.VA',
        // },
        // {
        //   text: 'HSBC Indonesia',
        //   value: 'HSBCI.FT',
        //   code: 'HSBCI.VA',
        // },
        // {
        //   text: 'OCBC Indonesia',
        //   value: 'OCBCI.FT',
        //   code: 'OCBCI.VA',
        // },
        // {
        //   text: 'UOB Indonesia',
        //   value: 'UOBI.FT',
        //   code: 'UOBI.VA',
        // },
        // {
        //   text: 'CIMB Niaga',
        //   value: 'CIMBN.FT',
        //   code: 'CIMBN.VA',
        // },
      ],
      godds: '',
    }
  },
  mounted() {
    Toast.allowMultiple()
    this.userData = JSON.parse(localStorage.getItem('user-info'))
    this.uid = this.userData[1].data.player.uid
    this.godds = this.optionData[0].value
  },
  methods: {
    // 充值按钮
    RechargeBtn() {
      var num = this.money.replace(/,/g, '')
      console.log(num);
      if (!/^[0-9]\d*$/.test(num)) {
        Toast(this.$t('Home.RechargeSu.Pleaseint'))
        return
      } // 表单验证
      if (num <= 0) {
        Toast(this.$t('Home.RechargeSu.Pleaseamount'))
        return
      }
      // if (this.name === '') {
      //   Toast(this.$t('Home.RechargeSu.Pleasename'))
      //   return
      // }

      // var params = {
      //   cmd: '201014',
      //   amount: num,
      //   currency: 'MYR',
      //   uid: this.uid,
      //   name: this.name,
      // }
      /**
       * TO DO
       * 新充值
       */
      let params = {
        cmd: '201021', // 充值
        uid: this.module.UIDEvent(),
        amount: num,
        goods: this.godds,
      }
      REQUEST_API(params).then((r) => {
        // if (r.status === 200) {
        //   let a = document.createElement('a')
        //   a.href = r.data.notifyList[0].data.redirect_to
        //   a.click()
        if (!r.data.sc) {
          let cont = r.data.notifyList[0].data.obj
          if (cont.indexOf('{"status":"error"') !== -1) {
            Toast(JSON.parse(cont).msg)
          } else {
            let di = document.createElement('DIV')
            di.innerHTML = cont
            document.body.appendChild(di)
            document.getElementById('pay').submit()
            document.body.removeChild(di)
          }
        } else {
          Toast(r.data.notifyList[0].data.msg)
        }
      })
    },
    // 返回
    onClickLeft() {
      this.$router.go(-1)
    },
    // 充值金额
    Recharge(data) {
      this.money = data.number
    },
  },
}
</script>
<style lang="less">
.goods2 {
  width: 100vw;
  height: 100vh;
  background-color: #dddbdb;
  .pull-down {
    // width: calc(100% - 32px);
    // padding: 0 16px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      font-size: 0.875rem;
      color: #646566;
    }
    .van-dropdown-menu {
      // width: 50%;
      font-size: 1rem;
      font-size: 0.9rem;
      .van-dropdown-menu__bar {
        // width: 90%;
        box-shadow: none;
        .van-dropdown-menu__title {
          width: 100%;
          // padding: 0;
          padding: 1px 8px 0 2px;
          text-align: left;
          color: #9a9a9a;
        }
      }
    }
  }
}
// 充值框
.Recharge {
  width: 90vw;
  margin: auto;
  margin-top: 1rem;
  background-color: white;
  border: 1px solid white;
  border-radius: 0.2rem;
  padding: 0.5rem;
  .txt {
    width: 90%;
    margin: 1rem auto;
  }
}
.Recharge-cu {
  width: 90%;
  margin: auto;
  border-bottom: 1px solid #dddbdb;
  span {
    color: #bd0011;
    font-size: 0.5rem;
  }
  input {
    width: 70%;
    border: 0px;
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}
.fast {
  width: 90%;
  margin: 0.8rem auto;
  font-size: 0.8rem;
  color: #9d9d9d;
}
.money {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  div {
    width: 22%;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    margin-bottom: 0.5rem;
    border: 1px solid #cfcfcf;
    font-size: 0.5rem;
    color: #bd0011;
  }
}
// 充值按钮
.btn-div1 {
  width: calc(90vw + 1rem);
  height: 2rem;
  display: block;
  text-align: center;
  line-height: 2rem;
  margin: 1rem auto;
  border: 1px solid black;
  background-color: black;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
}
// 充值事项盒子
.matter-box {
  width: 90vw;
  margin: auto;
  border: 1px solid white;
  background-color: white;
  border-radius: 5px;
  padding: 0.5rem;
  div {
    width: 95%;
    margin: auto;
    margin-top: 1rem;
    font-size: 0.8rem;
  }
  .Catatan {
    font-size: 1rem;
  }
}
</style>